<template>
    <div>
        <router-view />
    </div>
</template>
<script>

export default {
    name: 'NoInfoHeaderLayout',
}
</script>
<style scoped></style>
